// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gallery-item-js": () => import("/opt/build/repo/src/templates/gallery-item.js" /* webpackChunkName: "component---src-templates-gallery-item-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-artists-index-js": () => import("/opt/build/repo/src/pages/artists/index.js" /* webpackChunkName: "component---src-pages-artists-index-js" */),
  "component---src-pages-collection-featured-index-js": () => import("/opt/build/repo/src/pages/collection/featured/index.js" /* webpackChunkName: "component---src-pages-collection-featured-index-js" */),
  "component---src-pages-collection-index-js": () => import("/opt/build/repo/src/pages/collection/index.js" /* webpackChunkName: "component---src-pages-collection-index-js" */),
  "component---src-pages-collection-modern-index-js": () => import("/opt/build/repo/src/pages/collection/modern/index.js" /* webpackChunkName: "component---src-pages-collection-modern-index-js" */),
  "component---src-pages-collection-traditional-index-js": () => import("/opt/build/repo/src/pages/collection/traditional/index.js" /* webpackChunkName: "component---src-pages-collection-traditional-index-js" */),
  "component---src-pages-collection-unpublished-index-js": () => import("/opt/build/repo/src/pages/collection/unpublished/index.js" /* webpackChunkName: "component---src-pages-collection-unpublished-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("/opt/build/repo/src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-home-index-js": () => import("/opt/build/repo/src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-detail-js": () => import("/opt/build/repo/src/pages/posts/detail.js" /* webpackChunkName: "component---src-pages-posts-detail-js" */),
  "component---src-pages-posts-index-js": () => import("/opt/build/repo/src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-list-js": () => import("/opt/build/repo/src/pages/posts/list.js" /* webpackChunkName: "component---src-pages-posts-list-js" */),
  "component---src-pages-videos-index-js": () => import("/opt/build/repo/src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

