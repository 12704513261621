const $ = require( 'jquery')

exports.onClientEntry=()=>{
  require("./src/components/styles/index.css");
  require('bootstrap');
  require ("owl.carousel");
  require ("lightgallery");
  require ("lightslider");
  require ("lg-fullscreen");
  require ("lg-thumbnail");
  require ("lg-autoplay");
  require ("lg-zoom");
  require ("lg-pager");
  require ("trumbowyg");
  require ("trumbowyg/plugins/upload/trumbowyg.upload");
}



exports.onRouteUpdate=()=>{
  //global.$ && initMenu.default();
}